.mock_test_start_root{
    width: 100%;
    height: 100%;
    max-width: 1160px;
    min-width: 1160px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}
.mock_test_start_header_container{
    margin-top: 39px;
    display: flex;
    width: 100%;
}  
.mock_exam_element_header{
    margin-top: 40px;
    width: 100%;
    display: flex;
    gap: 14px;
}

.deco_box{
    position: relative;
}
.deco_box > span{
    position: absolute;
    top: 5px;
    left: 15px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.56px;
    color: #fff;
}

.mock_test_guide_root{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.guide_title_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.guide_title_container > span{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.guide_title_container > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #5b5b5b;
}
.description_container{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
    padding: 30px 30px;
    border-radius: 20px;
    background-color: #fdfaf2;
}
.description_container > .title{
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    line-height: 2.25;
    letter-spacing: -0.64px;
    color: #ff9300;
}
.description_container > .description{
    margin: 0;
    font-family: Pretendard;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #5b5b5b;
}

.btn_container{
    margin-top: 20px;
    display: flex;
    width: 100%;
}
.btn_container > button{
    width: 100%;
    height: 44px;
    box-shadow:none; 
    border-radius:0; 
    padding:0; 
    overflow:visible;
    cursor:pointer;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    text-align: center;
    color: #5b5b5b;
    border-radius: 8px;
    transition: all 0.2s ease;
}
.btn_container > button:hover{
    border: solid 1px #ffa711;
    color: #ffa700;
}