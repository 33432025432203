.not_found_root{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.not_found_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 920px;
}
.not_found_container > .image_container{

}
.not_found_container > .description_container{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.not_found_container > .description_container > span{
    font-family: Pretendard;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -1.28px;
    text-align: center;
    color: #5b5b5b;
}
.not_found_container > .description_container > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    text-align: center;
    color: #bababa;
}
.btn_container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
}
.btn_container > button{
    width: 200px;
    height: 44px;
    border-radius: 6px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    text-align: center;
    color: #5b5b5b;
    cursor: pointer;
}