.skeleton {
    background: #ddd;
    border-radius: 4px;
}
.skeleton.text {
    width : 100%;
    height : 16px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
}

.skeleton.avatar{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 100px;
    height : 100px;
}