.workbook_detail_root{
    width: 100%;
    height: 100%;
    /* max-width: 1160px;
    min-width: 1160px; */
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    /*overflow-x: hidden;*/
    /*overflow-y: auto;*/
}
.header{
    margin-top: 39px;
    display: flex;
    width: 100%;
}
