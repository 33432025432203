
/* mobile */
@media screen and (max-width: 768px) {
    .snb_root{
        z-index: 80;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: calc(360px - 32px);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        box-shadow: 0 -4px 8px 1px rgba(0,0,0, 0.15);
        transition: all 0.4s ease;
        background-color: #ffffff;
        gap: 16px;
    }
    .logo{
        width: 24px;
        height: 24px;
    }
    .arrow_svg{
        transform: rotate(180deg);
        transition: 0.3s ease;
    }
    .arrow_svg.activeMenu{
        transform: rotate(0deg);
    }
    .navbar_title{
        display: flex;
        align-items: center;
        width: 100%;
        height: 24px;
        border-radius: 10px;
        transition: all 0.2s ease;
        gap: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    .navbar_title > div{
        display: flex;
        align-items: center;
        width: calc(100% - 16px - 24px - 16px - 8px);
    }
    .navbar_title > div > span{
        font-family: Como;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: -0.72px;
        color: #252525;
        text-align: center;
    }
    .activeMenu > ul {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    .reset_ul{
        margin: 0;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        display: none;
        transition: all 0.4s ease;
    }
    
    .reset_ul > li{
        display: flex;
        width: calc(50% - 8px);
        height: 24px;
        align-items: center;
    }
    .navlink{
        width: 100%;
        text-decoration: none; 
        outline: none;
        font-family: Como;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.56px;
        color: #bababa;
        text-justify: center;
        cursor: pointer;
    }
    .navlink:hover, .navlink:active {
        text-decoration: none; 
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .snb_root{
        z-index: 80;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: calc(642px - 32px);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        box-shadow: 0 -4px 8px 1px rgba(0,0,0, 0.15);
        transition: all 0.4s ease;
        background-color: #ffffff;
        gap: 16px;
    }
    .logo{
        width: 24px;
        height: 24px;
    }
    .arrow_svg{
        transform: rotate(180deg);
        transition: 0.3s ease;
    }
    .arrow_svg.activeMenu{
        transform: rotate(0deg);
    }
    .navbar_title{
        display: flex;
        align-items: center;
        width: 100%;
        height: 24px;
        border-radius: 10px;
        transition: all 0.2s ease;
        gap: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    .navbar_title > div{
        display: flex;
        align-items: center;
        width: calc(100% - 16px - 24px - 16px - 8px);
    }
    .navbar_title > div > span{
        font-family: Como;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: -0.72px;
        color: #252525;
        text-align: center;
    }
    .activeMenu > ul {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
    .reset_ul{
        margin: 0;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        display: none;
        transition: all 0.4s ease;
    }
    
    .reset_ul > li{
        display: flex;
        width: calc(50% - 8px);
        height: 24px;
        align-items: center;
    }
    .navlink{
        width: 100%;
        text-decoration: none; 
        outline: none;
        font-family: Como;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -0.56px;
        color: #bababa;
        text-justify: center;
        cursor: pointer;
    }
    .navlink:hover, .navlink:active {
        text-decoration: none; 
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .snb_root{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 200px;
        gap: 5px;
        background-color: #ffffff;
    }
    .logo{
        margin-left: 7px;
    }
    .arrow_svg{
        margin-right: 7px;
        transform: rotate(180deg);
        transition: 0.3s ease;
    }
    .arrow_svg.activeMenu{
        transform: rotate(0deg);
    }
    .activeMenu > .navbar_title{
        background-color: #f7f7f7;
    }
    .activeMenu > ul {
        display: block;
    }
    .navbar_title{
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        border-radius: 10px;
        transition: all 0.2s ease;
        gap: 8px;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    .navbar_title > span{
        width: 128px;
        font-family: Como;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: -0.72px;
        color: #252525;
    }
    
    .reset_ul{
        margin: 0;
        margin-top: 10px;
        list-style: none;
        padding: 0;
        display: none;
    }
    
    .reset_ul > li{
        display: flex;
        width: 100%;
        height: 37px;
        align-items: center;
    }
    
    .navlink{
        width: 100%;
        margin-left: 20px;
        text-decoration: none; 
        outline: none;
        font-family: Como;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: -0.56px;
        color: #bababa;
        text-justify: center;
        cursor: pointer;
    }
    .navlink:hover, .navlink:active {
        text-decoration: none; 
    }
}

