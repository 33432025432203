.term_list{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.radio_style{
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    cursor: pointer;
}
.radio_style[type='checkbox'], .content_radio_root > div > input[type='radio']:checked {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 12px;
}
.radio_style[type='checkbox']:checked {
    background-color: #ffffff;
    border: 1px solid #ffa711;
}
.radio_style[type='checkbox']:checked::after {
    background-color: #ffa711;
    content: '';
    visibility: visible;
    width: 11px;
    height: 11px;
    min-width: 11px;
    min-height: 11px;
    border-radius: 10px;
}

.all_agree_box{
    display: flex;
    align-items: center;
    width: calc(100% - 40px);
    /* width: calc(440px - 40px); */
    height: calc(50px - 30px);
    padding: 15px 20px;
    /* background-color: #fdfaf2; */
    background-color: #f7f7f7;
    border-radius: 8px;
    gap: 10px;
}
.all_agree_box > span{
    font-family: Pretendard;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #252525;
}

.ordinary_term_box{
    display: flex;
    align-items: center;
    /* width: calc(440px - 30px - 2px); */
    width: calc(100% - 30px - 2px);
    height: calc(44px - 24px);
    padding: 12px 10px 12px 20px;
    border-radius: 6px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    gap: 10px;
}
.ordinary_term_box > p{
    margin: 0;
    /* width: 354px; */
    width: 100%;
    font-family: Pretendard;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #252525;
}