/* .quiz_root{
    width: calc(100% - 214px);
    height: 100%;
    margin: 0 107px;
    display: flex;
    flex-direction: column;
} */
.search_result_header{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.search_result_header > .title{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}
.search_result_header > .title > span{
    font-family: Pretendard;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -1.04px;
    color: #5b5b5b;
}
.search_result_header > .title > p{
    font-family: Pretendard;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -1.04px;
    color: #ffa700;
}
.list_container{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 28px;
    gap: 40px;
    list-style: none;
    padding-left: 0px;
    margin: 0;
    border-bottom: 2px solid #e4e4e4;
}
.list_container > li{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 80px;
    height: 30px;
    cursor: pointer;
}
.list_container > li.selected{
    -webkit-box-shadow: inset 0 -2px #5b5b5b;
    box-shadow: inset 0px -2px #5b5b5b;
}
.list_container > li > p{
    font-family: Pretendard;
    font-size: 16px;
    letter-spacing: -0.64px;
    color: #5b5b5b;
    font-weight: 500;
}
.list_container > li > p:first-child {

}
.list_container > li > p:last-child {
    color: #bababa;
}
.list_container > li > p.active {
    font-weight: bold;
    color: #ffba00;
}
.list_container > li:after{
    contnet: "";
    width: 80px;
    border-bottom: 1px solid black;
}
.search_result_container{}
.search_result_container > .result_container:last-child{
    margin-bottom: 80px;
}
.result_container{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.result_header{
    display: flex;
    width: 100%;
    justify-content:space-between;
}
.result_title{
    display: flex;
    gap: 5px;
}
.result_title > span{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.8px;
}
.result_title > span:first-child{
    color: #5b5b5b;
}
.result_title > span:last-child{
    color: #ffba00;
}
.result_header > .btn_container > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #bababa;
    text-decoration: underline;
    cursor: pointer;
}
.result_container > .quiz_container{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/* mobile */
@media screen and (max-width: 768px) {
    .quiz_root{
        width: calc(100% - 48px);
        height: 100%;
        margin: 0 24px;
        display: flex;
        flex-direction: column;
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .quiz_root{
        width: calc(100% - 214px);
        height: 100%;
        margin: 0 107px;
        display: flex;
        flex-direction: column;
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .quiz_root{
        width: calc(100% - 214px);
        height: 100%;
        margin: 0 107px;
        display: flex;
        flex-direction: column;
    }
}

