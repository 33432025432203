public_scroll::-webkit-scrollbar{
    width: 4px;
}

*::-webkit-scrollbar{
    width: 4px;
}

.public_scroll::-webkit-scrollbar-thumb{
    background: #e1e1e1;
    transition: all linear 0.1s;
}

*::-webkit-scrollbar-thumb{
    background: #BFBFBF;
    border-radius: 10px;
}


.public_scroll::-webkit-scrollbar-track{
    background: transparent;
}

*::-webkit-scrollbar-track{
    background: #E5E5E5;
    border-radius: 10px;
}
