.wrong_test_chart_root{
    display: flex;
    padding: 30px;
    min-width: calc(680px - 60px);
    height: calc(228px - 60px);
    border-radius: 20px;
    border: 1px solid #e4e4e4;
    background-color: #ffffff;
}
.wrong_test_chart_root.half_size{
    min-width: calc(440px - 60px - 2px);
}
.explain_container{
    width: 465px;
    height: 100%;
}

.header{
    display: flex;
    width: 100%;
    gap: 28px;
    align-items: center;
}
.header > span{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #5b5b5b;
}
.header > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #bababa;
}

.wrong_test_container{
    margin-top: 49px;
    width: 100%;
}
.wrong_test_container > span{
    font-family: Pretendard;
    font-size: 40px;
    font-weight: 600;
    line-height: 0.9;
    letter-spacing: -1.6px;
    
}
.wrong_test_container > span.passed{
    color: #ffa700;
}
.wrong_test_container > span.failed{
    color: #5b5b5b;
}
.wrong_test_container > span.perfect{
    color: #45cc79;
}
.feedback_container{
    margin-top: 20px;
    width: 100%;
}
.feedback_container > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #5b5b5b;
    white-space: pre-line
}

.chart_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(305px - 30px);
    height: 100%;
}
.chart_container > .chart{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.chart_description_container{
    margin-top: 5px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.chart_description_container > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 14px;
    letter-spacing: -0.56px;
    color: #bababa;
}