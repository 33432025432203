.content_radio_root{
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;    
}
.content_radio_root > .input_container{
    display: inline-flex;
    align-items: center;
}
.content_radio_root > .input_container > input[type='radio'], .content_radio_root > div > input[type='radio']:checked {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 12px;
}

.content_radio_root > .input_container > input[type='radio'] {
    background-color: #ffffff;
    border: 1px solid #ffa711;
}

.content_radio_root > .input_container >input[type='radio']:checked::after {
    background-color: #ffa711;
    content: '';
    visibility: visible;
    width: 14px;
    height: 14px;
    border-radius: 7px;
}

.content_radio_root > .label_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: calc(100% - 32px);
    /* height: 52px; */
    min-height: 52px;
    border-radius: 10px;
    transition: background-color 0.4s ease;
}

.content_radio_root > .label_container.select{
    background-color: #f9f9f9;
}
.content_radio_root > .label_container.answer{
    background-color: #fffaed;
}
.content_radio_root > .label_container.answer > label{
    color: #ff9300;
}

.content_radio_root > .label_container > label {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #5b5b5b;
    cursor:pointer;
}
.explain_p{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
    letter-spacing: -0.56px;
}
.explain_p.answer{
    color: #ff9300;
    white-space: nowrap;
}
.explain_p.select{
    color: #797979;
    white-space: nowrap;
}