.keyword_ele_main{
    display: flex;
    justify-content:center;
    align-items:center;
    /* padding: 0 10px; */
    height: 17px;
}
.keyword_ele_main > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.56px;
    color: #bababa;
}
