.mock_test_result_root{
    width: 100%;
    height: 100%;
    max-width: 1160px;
    min-width: 1160px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}
.mock_test_result_header{
    margin-top: 39px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mock_test_result_header > div:first-child{
    display: flex;
}
.mock_test_result_header > div:last-child{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.title{
    font-family: Pretendard;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: -1.04px;
    color: #5b5b5b;
}
.re_test{
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.64px;
    color: #d6d6d6;
    text-decoration: underline;
    cursor: pointer;
}
.result_preview{
    margin-top: 39px;
    width: 100%;
}
.result_infobox{
    margin-top: 60px;
}
.wrong_test_container{
    margin-top: 60px;
}