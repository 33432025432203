.quiz_test_view_root{
    width: 100%;
    height: 100%;
    /* max-width: 1160px;
    min-width: 1160px; */
    padding-right: 20px;
    display: flex;
    flex-direction: column;
}
.quiz_test_view_header{
    margin-top: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quiz_index_container{
    display: flex;
    gap: 4px;
    align-items: center;
}
.quiz_index_container > span:first-child{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #ffa700;
}
.quiz_index_container > span:last-child{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #ffa700;
}

.quiz_exit{
    cursor: pointer;
}
.quiz_exit > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    color: #bababa;
    text-decoration: underline;
}

.test_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.test_title{
    margin-top: 20px;
    /* width: 840px; */
}
.test_title > span{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}

.test_list_container{
    margin-top: 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
}

.test_btn_container{
    margin-top: 40px;
    margin-bottom: 93px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.test_btn_container > button{
    transition: all 0.4s ease;
    width: 100%;
    height: 44px;
    border-radius: 10px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    text-align: center;
    border: solid 1px #e4e4e4;
    background-color: #f9f9f9;
}

.test_btn_container > button:disabled {
    color: #e4e4e4;
    cursor: not-allowed;
}
.test_btn_container > button:enabled {
    color: #5b5b5b;
    cursor: pointer;
    background-color: #ffffff;
}