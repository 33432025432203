.more_btn_root{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    /* border: solid 1px #fdba01; */
    background-color: #fdfaf2;
    transition: background-color 0.2s ease;
    cursor: pointer;
}
.more_btn_root > span{
    font-family: "pretendard", sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.64px;
    text-align: center;
    color: #ffba00;
    transition: color 0.2s ease;
}
.more_btn_root:hover{
    border: solid 1px #fc9300;
    background-color: #fef0c6;
}
.more_btn_root:hover > span{
    color: #fc9300;
}
.more_btn_root:active{
    border: solid 1px #fdba01;
    background-color: #fdfaf2;
}
.more_btn_root:active > span{
    color: #ffba00;
}
