.modal_root{
    z-index: 99;
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.modal_background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #B2B2B2;
    opacity: 0.5;
}
.share_post_modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 422px;
    /* height: 231px; */
    border-radius: 16px;
    background-color: #fff;
}
.modal_header{
    margin-top: 30px;
    display: flex;
    padding: 0 27px 0 33px;
    width: calc(100% - 27px - 33px);
    justify-content: space-between;
    align-items: center;
}
.modal_title{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.modal_close_container{
    cursor: pointer;
}
.modal_body{
    display: flex;
    flex-direction: column;
    padding: 0 27px 0 33px;
    width: calc(100% - 27px - 33px);
}
.link_copy_title{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.url_container{
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: calc(100% - 20px);
    height: 46px;
    border-radius: 10px;
    border: solid 1px #fdba01;
    background-color: #fdfaf2;
    transition: border 0.2s ease;
    transition: background-color 0.2s ease;
}
.url_txt{
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #ffa700;
}
.copy_clipboard{
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.64px;
    text-align: right;
    color: #ffa700;
    cursor: pointer;
}

.url_container:hover{
    border: solid 1px #fdba01;
    background-color: #fef0c6;
}

.new_line{
    margin: 14.5px 0;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
}
.share_btn_container{
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 35px;
}
