.skeleton_topview_root{
    display: flex;
    flex-direction: column;
    width: calc(360px - 40px);
    height: calc(206px - 40px);
    padding: 20px;
    border-radius: 10px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
}
.body_container{
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.body_container > .content_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}