.send_mail_modal_root{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.modal_root > div{
    position: relative;
    width: 100%;
    height: 100%;
}
.background{
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}
.modal_container{
    z-index: 12;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(340px - 60px);
    height: calc(456px - 60px);
    padding: 30px;
    border-radius: 30px;
    background-color: #fff;
}
.modal_container > .description_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
}
.modal_container > .description_container > span:first-child{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #5b5b5b;
}
.modal_container > .description_container > span:last-child{
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #5b5b5b;
}
.btn{
    margin-top: 20px;
    width: 278px;
    height: 42px;
    border-radius: 8px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    color: #5b5b5b;
    cursor: pointer;
}