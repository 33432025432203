.platform_root{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 44px;
    height: 61px;
    cursor: pointer;
}
.icon_container{
    display: flex;
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    border-radius: 8px;
}
.title_container{
    margin-top: 5px;
    display: flex;
    width: 100%;
}
.title_container > span{
    width: 100%;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.48px;
    text-align: center;
  color: #9a9a9a;
    transition: color 0.2s ease;
    word-break:keep-all;
}
