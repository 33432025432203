.workbook_listview_root{
    width: 100%;
    /* height: 100%; */
    /* max-width: 1160px;
    min-width: 1160px; */
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    /*overflow-x: hidden;*/
    /*overflow-y: scroll;*/
}

.no_contents_container{
    margin-top: 185px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.favorite_container{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* max-width: 1160px;
    min-width: 1160px; */
}
.favorite_header{
    margin-top: 39px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.favorite_header > div{
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
}
.favorite_header > span{
    font-family: como, sans-serif;
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: -1.28px;
    color: #5b5b5b;
}
.favorite_help_container{
    margin-top: 36px;
    display: flex;
    align-items: center;
    gap: 6px;
}
.favorite_help_container > div{
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
}
.favorite_help_container > span{
    font-family: "pretendard", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.favorite_content_container{
    margin-top: 28px;
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
}
.favorite_content_container::-webkit-scrollbar{
    display: none;
}
.listview_root{
    margin-top: 50px;
    /* max-width: 1160px;
    min-width: 1160px; */
}
.listview_header{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
}
.listview_title_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.listview_title_container > div{
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
}
.listview_title_container > span{
    font-family: "pretendard", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.listview_body{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.listview_more_btn_container{
    margin-top: 17px;
    margin-bottom: 126px;
    display: flex;
    width: 100%;
}
.listview_filter_container{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

/*filter*/
.filter_element{
    cursor: pointer;
}
.filter_element > span{
    font-family: "pretendard", sans-serif;
    font-size: 0.875rem;
    letter-spacing: -0.56px;
    color: #d6d6d6;
    transition: color 0.2s ease;
}
.filter_element:hover > span{
    color: #797979;
}
.filter_element.active > span{
    color: #797979;
}
