.question_view_root{
    display: flex;
    flex-direction: column;
}
.question_view_title_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.question_view_title_container > span:first-child{
    font-family: Pretendard;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #ffa700;
}
.question_view_title_container > span:nth-child(2){
    font-family: Pretendard;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.question_container{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/* mobile */
@media screen and (max-width: 768px) {
    .question_btn_container{
        margin-top: 20px;
        margin-bottom: 100px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    .question_btn_container > div{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 10px;
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .question_btn_container{
        margin-top: 20px;
        margin-bottom: 100px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .question_btn_container > div{
        display: flex;
        gap: 10px;
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .question_btn_container{
        margin-top: 20px;
        margin-bottom: 100px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .question_btn_container > div{
        display: flex;
        gap: 10px;
    }
}

