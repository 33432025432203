.workbook_main{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.workbook_header_container{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 60px;
    /* min-width: 1440px; */
}
.workbook_body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    width: calc(100% - 40px);
    height: calc(100% - 61px);
    /* height: calc(100% - 80px); */
    /* min-width: 1400px; */
    gap: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid #e4e4e4;
}
.workbook_search_result_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 61px);
    /* min-width: 1400px; */
    gap: 40px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid #e4e4e4;
}

.right_side_container{
    display: flex;
    flex-direction: column;
    /* min-width: 1160px; */
    width: 100%;
    height: 100%;
}
.logo_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 169px;
    height: 50px;
    gap: 13px;
    cursor: pointer;
}
.logo_img{
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
}
/* mobile */
@media screen and (max-width: 768px) {
    .left_side_container{
        /*display: flex;*/
        /*width: 200px;*/
        /*height: 100%;*/
        position: absolute;
        top: 0;
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .left_side_container{
        /*display: flex;*/
        /*width: 200px;*/
        /*height: 100%;*/
        position: absolute;
        top: 0;
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .left_side_container{
        /*display: flex;*/
        /*width: 200px;*/
        /*height: 100%;*/
        position: sticky;
        top: 0;
    }
    .right_side_container{
        width: calc(100% - 220px);
    }
}

