.public_btn_root{
    display:flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease;
    gap: 6px;
    cursor: pointer;
}
.public_btn_root.border{
    width: 100%;
    min-width: 118px;
    height: 38px;
    border-radius: 10px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
}
.public_btn_root.no_border{
    display: flex;
    align-items: center;
    height: 40px;
    border: 0!important;
    background-color: #fff;
}
.public_btn_root > span{
    font-family: "pretendard", sans-serif;
    /* margin-top: 3px; */
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    color: #1e1e1e;
}

/* .public_btn_root.border:hover{
    border: solid 1px #fdba01;
    background-color: #fffaed;
}
.public_btn_root:hover > span{
    font-family: "pretendard", sans-serif;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.56px;
    color: #ffa700;
} */
.icon_container{
    display: flex;
    justify-content:center;
    align-items: center;
}
/* mobile */
@media screen and (max-width: 768px) {
    
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    
}

/* pc */
@media screen and (min-width: 1025px) {
   
}

