.no_search_result_root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.description_container{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}
.text_normal{
    font-family: Pretendard;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #5b5b5b;
}
.text_bold{
    font-family: Pretendard;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #5b5b5b;
}