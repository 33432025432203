.search_root{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* gap: 30px; */
}

.title_container{
    /* margin-top: 56px;
    margin-left: 10px; */
}
/* .search_box {
    z-index: 1;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    width: calc(920px - 50px);
    height: calc(64px - 32px);
    align-items: center;
    padding: 16px 30px 16px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 30px 0 rgba(198, 140, 0, 0.2);
    background-color: #fff;
    transition: height 0.3s ease;
} */
.search_box.active{
    height: calc(298px - 32px);
}
.search_area {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
}
.search_area > input {
    -webkit-appearance : none;
    -moz-appearance:none;
    appearance:none;
    width: calc(100% - 32px - 8px);
    border: 0;
    font-family: Pretendard;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    text-align: left;
    color: #5b5b5b;
}
.search_area > input::placeholder{
    font-family: Pretendard;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #bababa;
}
.search_area > input:focus {
    outline: none;
}
.search_area > button{
    width: 46px;
    background: inherit; 
    border:none; 
    box-shadow:none; 
    border-radius:0; 
    padding:0; 
    overflow:visible; 
    cursor:pointer;
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.56px;
    text-align: center;
    color: #ffba00;
    word-break: keep-all;
}

.keyword_container{
    display: none;
}
.keyword_container.active{
    display: flex!important;
    flex-direction: column;
    margin-top: 20px;
    /* width: 870px; */
    width: 100%;
    max-height: 200px;
    overflow: auto;
    gap: 10px;
}
.keyword_box{
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    gap: 10px; 
    cursor: pointer;
}
.keyword_box > div{
    width: 32px;
    height: 32px;
}
.keyword_box > span{
    font-family: Pretendard;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #bababa;   
}

/* mobile */
@media screen and (max-width: 768px) {
    .search_box {
        z-index: 1;
        margin-top: 84px;
        margin-left: 24px;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 48px);
        /* max-width: calc(312px - 32px); */
        height: calc(56px - 32px);
        align-items: center;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0 4px 30px 0 rgba(198, 140, 0, 0.2);
        background-color: #fff;
        transition: height 0.3s ease;
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .search_box {
        z-index: 1;
        margin-top: 84px;
        display: flex;
        flex-direction: column;
        margin-left: 107px;
        margin-right: 107px;
        width: calc(100% - 214px);
        max-width: calc(810px - 32px);
        height: calc(56px - 32px);
        align-items: center;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0 4px 30px 0 rgba(198, 140, 0, 0.2);
        background-color: #fff;
        transition: height 0.3s ease;
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .search_box {
        z-index: 1;
        margin-top: 84px;
        margin-left: 107px;
        margin-right: 107px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 214px);
        max-width: calc(1160px - 32px);
        height: calc(56px - 32px);
        align-items: center;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0 4px 30px 0 rgba(198, 140, 0, 0.2);
        background-color: #fff;
        transition: height 0.3s ease;
    }
}

