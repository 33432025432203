.result_preview_root {
    display: flex;
    /* padding: 50px 40px; */
    /* width: calc(100% - 80px); */
    /* height: calc(420px - 100px); */
    width: 100%;
    position: relative;
}
.background_image_container{

}
.info_box{
    z-index: 2;
    top: 39px;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 50px 40px;
}
.user_text{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    color: #ffffff;
}
.quiz_result_text{
    font-family: Pretendard;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: -1.28px;
    color: #ffffff;
}
.p_tag_container{
    margin-top: 29px;
    display: flex;
    gap: 5px;
}
.p_tag_container > p{
    margin: 0;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #ffffff;
}

.test_info_container{
    display: flex;
    flex-direction: column;
}
.test_description{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #fff;
}

.result_qualk_image_container {

}