.infinite_scroll{
    padding-bottom: 81px;
}
.infinite_scroll:before{
    display: block;
    position: absolute;
    bottom: 12px;
    left: 50%;
    width: 1px;
    height: 60px;
    background-color: #ffa700;
    opacity: 0.35;
    content: '';
}
.infinite_scroll > .text{
    font-family: Pretendard;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    color: #6b6b6b;
}

.infinite_scroll > .deco{
    position: absolute;
    bottom: 0;
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    animation: deco 4s ease infinite;
}
.infinite_scroll > .deco::before{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 167, 0, 0.2);
    border-radius: 100%;
    content: '';
    animation: big 4s ease infinite;
}
.infinite_scroll > .deco::after{
    display: block;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 3px);
    z-index: 2;
    width: 7px;
    height: 7px;
    background-color: #ffa700;
    border-radius: 7px;
    content: '';
}
@keyframes big{
    0%{
        transform: scale(-0.4);
        opacity: 0;
    }
    60%{
        transform: scale(1);
        opacity: 1;
    }
    
    100%{
        transform: scale(-0.1);
        opacity: 0;
    }
}
@keyframes deco{
    0% {
        transform: translateY(-40px);
    }
    50% {
        transform: translateY(20px);
    }
    60% {
        transform: translateY(20px);
    }
    70% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-40px);
    }
}