.quiz_select_shortcut_root{
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: calc(100% - 2px - 60px);
    height: calc(167px - 2px - 60px);
    border-radius: 10px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    cursor: pointer;
}
.header_container{
    display: flex;
    width: 100%;
    gap: 10px;
}
.first_color_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 30px;
    border-radius: 8px;
}
.first_color_box > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: -0.56px;
    text-align: left;
    color: #fff;
}
.second_color_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 30px;
    border-radius: 8px;
    background-color: #fdfaf2;
}
.second_color_box > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: -0.56px;
    text-align: left;
    color: #ffba00;
}
.text_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}
.title{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.8px;
    color: #5b5b5b;
}
.description{
    font-family: Pretendard;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #5b5b5b;
}
