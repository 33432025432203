.find_password_root{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.find_password_root > .bg_container{
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}

.find_password_root > .modal_body{
    z-index: 12;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: calc(520px - 48px);
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
}

.header_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.header_container > span{
    font-family: Pretendard;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: -0.8px;
    color: #252525;
}
.header_container > div{
    cursor: pointer;
}
.description_container{
    margin-top: 4px;
    width: 100%;
}
.description_container > span{
    font-family: Pretendard;
    font-size: 12px;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #bababa;
}
.input_container{
    margin-top: 16px;
    width: 100%;
}
.input_container > input{
    width: calc(100% - 32px);
    height: calc(56px - 32px);
    border-radius: 6px;
    padding: 16px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #5b5b5b;
}
.input_container > input::placeholder{
    font-family: Pretendard;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: -0.64px;
    color: #bababa;
}
.btn_container{
    margin-top: 24px;
    width: 100%;
}
.btn_container > button{
    outline: none;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    /* border: solid 1px #e4e4e4; */
    outline: none;
    border: none;
    background-color: #FFA700;
    font-family: Pretendard;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: -0.56px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

/* mobile */
@media screen and (max-width: 768px) {
    .find_password_root > .modal_body{
        z-index: 12;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: calc(320px - 48px);
        padding: 24px;
        border-radius: 16px;
        background-color: #fff;
    }
}