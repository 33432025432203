.answer_explain_root{
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.title_container{
    display: flex;
    width: 100%;
}
.title_container > span{
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: -0.72px;
    color: #5b5b5b;
}
.content_container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    width: calc(100% - 32px);
    border-radius: 10px;
    background-color: #f7f7f7;
}
.answer_container{
    width: 100%;
}
.answer_container > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.63;
    letter-spacing: -0.64px;
    color: #252525;
}
.explain_container{
    margin-top: 20px;
}
.explain_container > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    line-height: 1.71;
    letter-spacing: -0.56px;
    color: #5b5b5b;
}
.reference_container{
    margin-top: 30px;
    width: 100%;
}
.reference_container > span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.56px;
    color: #ffa700;
}
.reference_container > span:nth-child(2){
    text-decoration: underline;
}
