.login_page_root{
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}
.login_page_root > .header_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
}
/* .login_page_root > .body_container{
    margin-top: 40px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 160px;
}
.login_page_root > .body_container > section:first-child{
    width: 440px;
}
.login_page_root > .body_container > section:last-child{
    width: 800px;
    height: 775px;
    background-color: #FFBA00;
    border-radius: 12px;
} */

/* mobile */
@media screen and (max-width: 768px) {
    
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .login_page_root > .body_container{
        margin-top: 40px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        padding: 0 24px;
        width: calc(100% - 48px);
        gap: 80px;
    }
    .login_page_root > .body_container > section:first-child{
        /* width: calc(50% - 40px - 24px); */
        width: 476px;
    }
    /* .login_page_root > .body_container > section:last-child{
        width: calc(50% - 40px - 24px);
        height: auto;
        background-color: #FFBA00;
        border-radius: 12px;
    } */
}

/* pc */
@media screen and (min-width: 1025px) {
    /* .login_page_root > .body_container{
        margin-top: 40px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 160px;
    }
    .login_page_root > .body_container > section:first-child{
        width: 50%;
    }
    .login_page_root > .body_container > section:last-child{
        width: 50%;
        height: auto;
        background-color: #FFBA00;
        border-radius: 12px;
    } */
    .login_page_root > .body_container{
        margin-top: 40px;
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        padding: 0 24px;
        width: calc(100% - 48px);
        gap: 80px;
    }
    .login_page_root > .body_container > section:first-child{
        width: calc(50% - 40px - 24px);
    }
    .login_page_root > .body_container > section:last-child{
        width: calc(50% - 40px - 24px);
        height: auto;
        background-color: #FFBA00;
        border-radius: 12px;
    }
}