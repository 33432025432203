.search_bar_main{
    display: flex;
    flex-direction: row;
    /*justify-content:center;*/
    align-items: center;
    /*padding: 0 15px;*/
    width: 320px;
    max-width: 800px;
    height: 38px;
    border-bottom: solid 1px #e4e4e4;
    background-color: transparent;
    gap: 16px;
    transition: all 0.2s ease;
}
.input_container{
    display: flex;
    width: calc(100% - 24px);
    align-items: center;
}
.search_input{
    font-family: "pretendard", sans-serif;
    font-weight: 500;
    margin-top: 3px;
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: none;
    font-size: 0.875rem;
    color: #5b5b5b;
}
.search_input::placeholder{
    font-size: 0.875rem;
    letter-spacing: -0.56px;
    color: #bababa;
    transition: color 0.2s ease;
}
.search_bar_main:hover{
    border-bottom: solid 1px #9a9a9a;
}
.search_bar_main:hover > .input_container > .search_input::placeholder{
    color: #5b5b5b;
}
.search_bar_main.active{
    border-bottom: solid 1px #fdba01;
}
.search_bar_main.active > .input_container > .search_input::placeholder{
    color: #5b5b5b;
}

/* mobile */
@media screen and (max-width: 768px) {
    .search_bar_main{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 20px;
        width: calc(100% - 40px);
        height: 38px;
        border-bottom: solid 1px #e4e4e4;
        background-color: transparent;
        gap: 16px;
        transition: all 0.2s ease;
    }
}

/* pad */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .search_bar_main{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 20px;
        width: calc(100% - 40px);
        height: 38px;
        border-bottom: solid 1px #e4e4e4;
        background-color: transparent;
        gap: 16px;
        transition: all 0.2s ease;
    }
}

/* pc */
@media screen and (min-width: 1025px) {
    .search_bar_main{
        display: flex;
        flex-direction: row;
        /*justify-content:center;*/
        align-items: center;
        /*padding: 0 15px;*/
        width: 320px;
        max-width: 800px;
        height: 38px;
        border-bottom: solid 1px #e4e4e4;
        background-color: transparent;
        gap: 16px;
        transition: all 0.2s ease;
    }
}