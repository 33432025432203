.workbook_element_root{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: solid 1px #e4e4e4;
    padding: 20px;
    transition: background-color 0.2s ease;
    cursor: pointer;
}
.workbook_element_root:hover {
    padding: 20px;
    border: solid 1px #fdba01;
}
.workbook_element_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.workbook_title{
    display: flex;
    gap: 5px;
}
.workbook_title > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: -0.56px;
    color: #ffa700;
}

.workbook_element_right_side_container{
    display: flex;
    flex-direction: row;
    gap: 14.5px;
    align-items: center;
}
.workbook_element_right_side_container > div:first-child{
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}
.workbook_element_right_side_container > div:first-child > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.56px;
    color: #bababa;

}
.eye_icon{
    width: 22px;
    height: 22px;
}
.workbook_element_right_side_container > div:nth-child(2){
    width: 1px;
    height: 14px;
    background-color: #e4e4e4;
}
.workbook_element_right_side_container > div:last-child{

}
.workbook_element_right_side_container > div:last-child > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.56px;
    color: #bababa;
}

.workbook_element_title_container{
    margin-top: 15px;
    width: 100%;
    min-height: 60.11px;
}
.workbook_element_title_container > span{
    font-family: "pretendard", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.72px;
    color: #797979;
    line-height: 1.56;
    letter-spacing: -0.72px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}
.workbook_keyword_container{
    margin-top: 40px;
    display:flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    min-height: 24px;
    flex-wrap: wrap;
}
