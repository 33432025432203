.question_element_root{
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    gap: 10px;
}

.select_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    max-width: 23px;
    max-height: 23px;
    border: solid 1px #e4e4e4;
    background-color: #fff;
    border-radius: 20px;
}
.select_box.correct{
    border: solid 1px #ffa711!important;
}
.select_box.correct > .circle{
    width: 14px;
    height: 14px;
    background-color: #ffa700;
    border-radius: 10px;
}

.title_container{
    display: flex;
    padding: 12px 16px;
    align-items: center;
    width: calc(100% - 32px);
    height: calc(100% - 24px);
    border-radius: 10px;
}
.title_container.correct{
    background-color: #fdfaf2;
}
.title_container > span{
    font-family: Pretendard;
    font-size: 1rem;
    letter-spacing: -0.56px;
    color: #000000;
}
.title_container.correct > span{
    font-family: Pretendard;
    font-size: 1rem;
    letter-spacing: -0.56px;
    color: #000000;
}