.fade_in{
    animation: fadeIn 0.2s;
}

.fade_and_slide{
    animation: .3s ease-in-out forwards fadeAndSlide;
}

.fade_in_out {
    animation: fadeInOut .5s ease-in-out;
}

.left_slide_modal{
    animation: leftSlideModal .3s ease;
}

@keyframes fadeIn {
    from{
        opacity: 0
    }
    to{
        opacity: 1;
    }
}

@keyframes fadeAndSlide{
    from {
        transform: translateY(-10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

@keyframes leftSlideModal{
    from{
        transform: translateX(-30px);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}