.user_menu_root{
    z-index: 30;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.user_menu_root > .user_menu_background{
    z-index: 31;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}
.user_menu_root > .user_menu_body{
    z-index: 32;
    position: absolute;
    width: 290px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 0px 12px 12px 0px;
}
.user_menu_root > .user_menu_body > .header_container{
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.user_menu_root > .user_menu_body > .header_container > div{
    margin-right: 24px;
    cursor: pointer;
} 
.user_menu_root > .user_menu_body > .user_profile_container{
    margin-top: 24px;
    display: flex;
    padding: 0 24px;
    width: 100%;
}
.user_menu_root > .user_menu_body > .user_profile_container > .user_profile{
    display: flex;
    align-items: center;
    gap: 16px;
}
.user_name_container{
    width: 126px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user_name_container > span{
    font-family: Pretendard;
    font-size: 1rem;
    color: #5b5b5b;
}
.user_login_container{
    width: 126px;
    cursor: pointer;
}
.user_login_container > span{
    font-family: Pretendard;
    font-size: 1rem;
    color: #5b5b5b;
    text-decoration: underline;
}
.modify_profile{
    display: flex;
}
.modify_profile > span{
    font-family: Pretendard;
    font-size: 0.75rem;
    color: #ffa700;
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
}
.new_line{
    margin: 24px;
    width: calc(100% - 48px);
    height: 1px;
    background-color: #e4e4e4;
}
.li_container{
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    width: 100%;
    gap: 16px;
}
.li_container > .li_ele{
    width: 210px;
    height: 32px;
    cursor: pointer;
}
.li_container > .li_ele > span{
    font-family: Pretendard;
    font-size: 1rem;
    font-weight: 600;
    color: #252525;
}

.menu_control_list{
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    width: 100%;
    gap: 8px;
}
.menu_control_list > .control_ele{
    display: flex;
    align-items: center;
    width: 234px;
    height: 32px;
    cursor: pointer;
    gap: 8px;
}
.menu_control_list > .control_ele > span{
    font-family: Pretendard;
    font-size: 0.875rem;
    font-weight: 600;
    color: #444444;
}